<template>
  <div id="shopGoods">
    <HomePageTop></HomePageTop>
    <!-- 店铺名字 -->
    <nav> {{ $fanyi('店铺') }} {{ $route.query.shopName }}</nav>
    <h1 class="bodyTitle">{{ $fanyi('商品推荐') }}</h1>
    <!-- 店铺商品页 -->
    <div class="SearchConterCrosswise" v-if="showList.length != 0">
      <div class="SearchConterCrosswiseCon" v-for="(item, index) in showList" :key="index" @click="
        $fun.toCommodityDetails({
          goods_id: item.goodsId,
          shop_type: item.shopType,
        })
        ">
        <!-- 放图片的div -->
        <div class="SearchConterCrosswiseImg">
          <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
          <el-image :src="item.imgUrl" class="goodsImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <!-- <loadingImg :src="item.imgUrl" /> -->
        </div>
        <!-- 显示其他信息的div -->
        <div class="rests">
          <p :title="item.title" class="goodsTitle">{{ item.titleT }}</p>
          <p>
            <span>${{
              $fun.EURNumSegmentation(
                $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
              )
            }}（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）</span>
          </p>
        </div>
      </div>
    </div>
    <div class="donNotHaveGoods" v-loading="loading" v-if="showList.length == 0">
      {{ txt }}
    </div>
    <!-- 分页 -->
    <!-- <div class="allpag" v-if="showList.length > 14">
      <a href="javascript:;" @click="
        form.page = 1
      getData()
        ">{{ $fanyi('回到首页') }}</a>
      <el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="15"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div> -->
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '../../components/foot/Foot.vue'
import HomePageTop from '../../components/head/HomePageTop.vue'
export default {
  data() {
    return {
      form: {},
      total: 0,
      keyWord: '',
      lists: [],
      showList: [],
      txt: '',
      loading: true,
      isImgSearch: false,
    }
  },
  components: {
    HomePageTop,
    Foot,
  },
  computed: {},
  created() {
    this.getData()
  },

  methods: {
    // 获取列表数据
    getData() {
      this.form.pageSize = 15
      this.form.keyWord = this.$fanyi('衣服')
      this.form.shop_type = 1688
      // 获取店铺商品数据
      // this.$api
      //   .getShopAllGoods({
      //     shopName: this.$route.query.shopName,
      //     shop_type: 1688,
      //     page: this.form.page,
      //   })
      //   .then((result) => {
      //     this.loading = false
      //     if (result.data.length == 0)
      //       return (this.txt = this.$fanyi('此店铺暂无商品'))
      //     this.showList = result.data.goods
      //     this.lists = this.showList.slice(0, 15)
      //     this.total = this.showList.length
      //     document.body.scrollTop = document.documentElement.scrollTop = 0

      //     this.$forceUpdate()
      //   })
      //   .catch((err) => { })
      this.$api
        .getShopAllGoods({
          shopName: this.$route.query.shopName,
          shop_type: 1688,
          page: this.form.page,
          shop_id: this.$route.query.shopId,
        })
        .then((result) => {
          this.loading = false

          if (result.code !== 0) {
            return (this.txt = this.$fanyi('此店铺暂无商品'))
          }
          if (result.data.goods.length == 0) {
            return (this.txt = this.$fanyi('此店铺暂无商品'))
          }
          this.showList = result.data.goods

          this.total = this.showList.length
          document.body.scrollTop = document.documentElement.scrollTop = 0

          this.$forceUpdate()
        })
        .catch((err) => { })
    },
    handleCurrentChange(val) {
      let num = (val - 1) * 15
      this.lists = this.showList.slice(num, num + 15)
      this.$forceUpdate()
    },
  },
}
</script>
<style lang="scss" scoped>
#shopGoods {
  @import '../../css/mixin';
  width: 100%;
  background-color: white;

  nav {
    width: $pageWidth;
    margin: 0 auto 30px;
    height: 88px;
    font-size: 18px;
    color: #222222;
    line-height: 28px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e2e2e2;
  }

  .bodyTitle {
    width: $pageWidth;
    margin: 0 auto 20px;
    line-height: 36px;
    margin-bottom: 30px;
    font-size: 24px;
    color: #222222;
    letter-spacing: 1px;
  }

  .SearchConterCrosswise {
    width: $pageWidth;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    min-height: 500px;

    >div {
      margin-right: 40px;
      margin-bottom: 15px;

      &:last-child {
        margin-right: 0px;
      }

      background-color: white;
      width: 240px;
      overflow: hidden;

      >div {
        cursor: pointer;

        &.SearchConterCrosswiseImg {
          overflow: hidden;
          height: 240px;
          position: relative;

          /deep/.el-image.goodsImg {
            $imgSize: 240px;
            width: $imgSize;
            height: $imgSize;
            transition: 0.3s;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;

            i {
              font-size: $imgSize/4;
              line-height: $imgSize;
            }

            &:hover {
              transform: scale(1.2);
            }
          }

          >img {
            transition: 0.3s;
            width: 100%;
            min-height: 100%;
          }

          &:hover {
            >img {
              transform: scale(1.2, 1.2);
            }
          }
        }

        &.rests {
          box-sizing: border-box;
          padding: 20px 0px 0px;

          p {
            font-size: 12px;

            font-weight: 400;
            color: #000000;

            &.goodsTitle {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin-bottom: 10px;
            }

            &:nth-child(2) {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              >span {
                &:first-child {
                  font-size: 20px;
                  font-weight: bold;
                  color: #ff730b;
                  line-height: 36px;
                }
              }
            }

            .collect {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              span {
                font-size: 14px;

                color: #888888;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .donNotHaveGoods {
    width: $pageWidth;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    font-size: 35px;
    color: #888888;
  }

  .allpag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0 25px;

    >a {
      display: inline-block;
      border: 1px solid #dcdfe6;
      height: 28px;
      background-color: white;
      text-align: center;
      padding: 0 10px;
      font-size: 13px;
      line-height: 28px;
      border-radius: 2px;
    }

    /* 分页被选中的页码样式 */
    /deep/.el-pager li {
      margin: 0 3px;
      border-radius: 4px;
      box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

      &:hover {
        color: $homePageSubjectColor;
      }

      &.active {
        background-color: $homePageSubjectColor;
        color: white;
      }
    }
  }
}
</style>
